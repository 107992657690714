<template>
    <div>
        <comment
            :entry="comment"
            v-for="comment in comments"
            v-bind:key="comment.id"
        ></comment>
    </div>
</template>
<script>
import axios from "axios";
import Comment from "../components/Comment.vue";
export default {
    name: "CommentList",
    data() {
        return {
            comments: {},
        };
    },
    created() {
        this.fetchComments();

        this.$eventBus.$on("comment-created", this.fetchComments);
    },
    methods: {
        fetchComments() {
            axios
                .get(`/api/v1/items/${this.id}/comments`, {
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                    },
                })
                .then((response) => {
                    this.comments = response.data.data;
                });
        },
    },
    props: {
        id: {
            default: null,
            type: String,
        },
    },
    components: {
        Comment,
    },
};
</script>
