<template>
    <div>
        <div class="mb-3">
            <button :class="[
                btnClass,
                selection === 'all'
                    ? 'btn-secondary '
                    : 'btn-outline-secondary',
            ]" @click="filter('all')">
                {{ lang.all }}
            </button>
            <button :class="[
                btnClass,
                selection === 'public'
                    ? 'btn-secondary '
                    : 'btn-outline-secondary',
            ]" @click="filter('public')">
                {{ lang.in_showroom }} ({{ publicCount }})
            </button>
            <button :class="[
                btnClass,
                selection === 'valid'
                    ? 'btn-secondary '
                    : 'btn-outline-secondary',
            ]" @click="filter('valid')">
                {{ lang.complete }} ({{ validCount }})
            </button>
            <button :class="[
                btnClass,
                selection === 'invalid'
                    ? 'btn-secondary '
                    : 'btn-outline-secondary',
            ]" @click="filter('invalid')">
                {{ lang.incomplete }} ({{ invalidCount }})
            </button>
        </div>
        <div class="card shadow-sm mt-2">
            <div class="card-body p-0">
                <div class="p-5 text-center" v-if="filteredItems.length === 0">
                    {{ lang.no_records_found }}
                </div>
                <div v-if="filteredItems.length">
                    <showroom-row :item="item" :key="item.id" v-for="item in filteredItems"
                        @showroom-row-change="getFilteredItems" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable no-undef */
import ShowroomRow from "../components/ShowroomRow.vue";

export default {
    name: "ShowroomList",
    data: () => ({
        btnClass: "btn btn-sm me-1 mb-1 px-3",
        filteredItems: {},
        items: {},
        selection: "all",
        lang: {
            no_records_found: window.uiLanguage.no_records_found,
            all: window.uiLanguage.all,
            incomplete: window.uiLanguage.incomplete,
            complete: window.uiLanguage.complete,
            in_showroom: window.uiLanguage.in_showroom,
        },
    }),
    components: {
        ShowroomRow,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    created() {
        this.items = this.data;
        this.getFilteredItems();
    },
    methods: {
        filter(selection) {
            this.selection = selection;

            this.getFilteredItems();
        },
        getFilteredItems() {
            if (this.selection === "all") {
                this.filteredItems = this.items;
            }

            if (this.selection === "public") {
                this.filteredItems = this.items.filter(
                    (item) => item.is_public === true
                );
            }

            if (this.selection === "valid") {
                this.filteredItems = this.items.filter(
                    (item) =>
                        item.can_be_published === true &&
                        item.is_public === false
                );
            }

            if (this.selection === "invalid") {
                this.filteredItems = this.items.filter(
                    (item) =>
                        item.can_be_published === false &&
                        item.is_public === false
                );
            }
        },
    },
    computed: {
        publicCount() {
            return this.items.filter((item) => item.is_public === true).length;
        },
        validCount() {
            return this.items.filter(
                (item) =>
                    item.can_be_published === true && item.is_public === false
            ).length;
        },
        invalidCount() {
            return this.items.filter(
                (item) =>
                    item.can_be_published === false && item.is_public === false
            ).length;
        },
    },
};
</script>
