<template>
    <div class="viewer-bottombar">
        <div class="viewer-nav">
            <button id="viewer-previous" :disabled="!prev" @click="prevItem()" class="viewer-nav-btn viewer-previous">
                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                    <path fill="#fff"
                        d="M8.5,12.8l5.7,5.6c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0c0.4-0.4,0.4-1,0-1.4l-4.9-5l4.9-5c0.4-0.4,0.4-1,0-1.4c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3l-5.7,5.6C8.1,11.7,8.1,12.3,8.5,12.8C8.5,12.7,8.5,12.7,8.5,12.8z" />
                </svg>
            </button>

            <span class="viewer-pagination">
                {{ pagination() }}
            </span>
            <button id="viewer-next" :disabled="!next" @click="nextItem()" class="viewer-nav-btn viewer-next">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="#fff"
                        d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z" />
                </svg>
            </button>
        </div>
    </div>
</template>
<script lang="ts">

export default {
    name: "BottomBar",
    props: {
        prev: {
            type: Boolean,
            required: false
        },
        next: {
            type: Boolean,
            required: false
        },
        pagination: {
            type: Function,
            required: true
        }
    },
    methods: {
        prevItem() {
            this.$emit('prev-item');
        },
        nextItem() {
            this.$emit('next-item');
        }
    },
}
</script>
<style lang="scss">
.viewer {
    &-next {
        margin-left: 0.5rem;
    }

    &-previous {
        margin-right: 0.5rem;
    }

    &-bottombar {
        bottom: 4.375rem;
        position: absolute;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        color: #fff;
        z-index: 20;

        @media (min-width: 992px) {
            right: 491px;
            bottom: 0;
        }
    }

    &-pagination {
        min-width: 7rem;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
    }

    &-nav {
        align-items: center;
        background: var(--yt-bg-oil-700);
        display: flex;
        width: 100%;

        &-btn {
            width: 3rem;
            height: 3rem;
            border: 0;
            background: transparent;

            &:focus {
                outline: none;

                @media (min-width: 992px) {
                    outline: inherit;
                }
            }

            &:disabled svg {
                opacity: 0.5;
            }

            &.active svg {
                filter: drop-shadow(0 0 3px rgb(255, 255, 255));
            }
        }
    }
}
</style>
