import "./browse/browse-navigator";
import "./browse/browse-viewer";
import "./checkbox";
import "./choices";
import "./dashboard";
import "./datatable";
import "./dynamic-views/drag";
import "./dynamic-views/filter";
import "./item/image";
import "./object-viewer";
import "./showroom";
import "./ui/stats-counter";
import "./ui/toolbar";
import "./ui/confirm";
import "./vue";
import Snackbar from "node-snackbar";
import * as Sentry from "@sentry/browser";
import $ from 'jquery';
import axios from "axios";
import * as Bootstrap from "bootstrap";

window.$ = window.jQuery = $;

import.meta.glob(["../images/**"]);

if (import.meta.env.VITE_SENTRY_FRONTEND_DSN !== "null") {
    Sentry.init({
        dsn: "https://241a85c0991446a3840fe6ecca39baaa@o931149.ingest.sentry.io/4504248464637952",
        integrations: [Sentry.browserTracingIntegration()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: import.meta.env
            .VITE_SENTRY_FRONTEND_TRACES_SAMPLE_RATE,
    });
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]') as HTMLInputElement;

if (token instanceof HTMLMetaElement) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
}

jQuery(function () {

    const token = document.head.querySelector('meta[name="csrf-token"]') as HTMLInputElement;

    if (token instanceof HTMLMetaElement) {
        jQuery.ajaxSetup({
            headers: { "X-CSRF-TOKEN": token.content },
        });
    }

    jQuery('[data-bs-toggle="tooltip"]').tooltip();
});

document.addEventListener("DOMContentLoaded", function () {
    if (window.snackbarText) {
        Snackbar.show({
            text: window.snackbarText,
            showAction: false
        });
    }

    const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
    const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new Bootstrap.Dropdown(dropdownToggleEl))
});
