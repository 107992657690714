<template>
    <div>
        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <textarea class="form-control" rows="4" required name="body" :placeholder="lang.what_are_your_thougths"
                    v-model="body"></textarea>
            </div>
            <div class="form-check mb-2 mb-md-0">
                <input type="checkbox" class="form-check-input" v-model="follow_new_replies" id="notify" />
                <label class="form-check-label text-dark" for="notify">
                    {{ lang.notify_me_of_follow_up_replies }}
                </label>
            </div>

            <div class="text-end">
                <button class="btn btn-primary" type="submit">
                    {{ lang.post_comment }}
                </button>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    name: "CommentForm",
    data() {
        return {
            body: "",
            follow_new_replies: false,
            lang: {
                notify_me_of_follow_up_replies:
                    window.uiLanguage.notify_me_of_follow_up_replies,
                post_comment: window.uiLanguage.post_comment,
                what_are_your_thougths: window.uiLanguage.what_are_your_thougths,
            },
        };
    },
    props: {
        id: {
            default: null,
            type: String,
        },
    },
    methods: {
        submitForm() {
            const data = {
                attributes: {
                    body: this.body,
                    follow_new_replies:
                        this.follow_new_replies === true ? 1 : 0,
                },
                type: "comments",
                relationships: {
                    commentable: {
                        data: {
                            type: "items",
                            id: this.id,
                        },
                    },
                },
            };
            axios
                .post(
                    "/api/v1/comments",
                    { data: data },
                    {
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                        },
                    }
                )
                .then(() => {
                    this.$eventBus.$emit("comment-created");
                    this.body = "";
                    this.follow_new_replies = false;
                });
        },
    },
};
</script>
