<template>
    <div class="text-center">
        <button :class="{ active: likedByUser }" @click="toggle" class="mx-auto btn-like" :disabled="btnDisabled">
            <div>
                <img width="25" height="25" src="../../images/icon/like.svg" alt="" />
                <div v-if="likedByUser">{{ total }}</div>
            </div>
        </button>
        <div class="mt-2 text-white">Waarderen</div>

        <div class="mt-4">
            <small class="total d-flex align-items-center justify-content-center">
                <img class="icon-like me-2" width="12" height="12" src="../../images/icon/like.svg" alt="" />
                {{ total }}</small>
        </div>
    </div>
</template>
<script>
import Snackbar from "node-snackbar";
export default {
    name: "Like",
    data() {
        return {
            likeId:
                this.like === "false" || this.like === false
                    ? false
                    : this.like,
            likedByUser:
                this.like === "false" || this.like === false ? false : true,
            total: parseInt(this.likeCount),
            btnDisabled: false,
        };
    },
    props: {
        id: {
            default: null,
            type: String,
        },
        like: {
            default: null,
        },
        likeCount: {
            default: "0",
        },
    },
    methods: {
        toggle() {
            // Disable button
            this.btnDisabled = true;

            // Show Snackbar
            if (!this.likeId) {
                Snackbar.show({
                    text: window.uiLanguage.notification_appreciation,
                    showAction: false,
                });
            }

            // Store current values
            const prevLikedByUser = this.likeId === false ? false : true;
            const prevtotal = this.total;

            // Set values that are used in the layout
            this.likedByUser = this.likeId === false ? true : false;
            this.total =
                this.likeId === false ? this.total + 1 : this.total - 1;

            // Set relationship data
            let relationship = {
                likeable: {
                    data: {
                        type: "items",
                        id: this.id,
                    },
                },
            };

            // Set default data
            let data = { attributes: { like: true } };

            if (this.likeId !== false) {
                // Overwrite data for deleting
                data = { id: this.likeId };
            }

            data.type = "likes";
            data.relationships = relationship;

            // Send request to API
            axios
                .post(
                    this.likeApiRoute,
                    {
                        _method: this.likeId === false ? "POST" : "DELETE",
                        data: data,
                    },
                    {
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                        },
                    }
                )
                .then((response) => {
                    // Update like ID
                    this.likeId =
                        response.data === "" ? false : response.data.data.id;

                    // Update like count in DOM
                    const el = document.querySelector(
                        `[data-shot-id="${this.id}"] .shot__likes`
                    );
                    if (el !== null) {
                        el.innerText = this.total;
                    }

                    // Enable button
                    this.btnDisabled = false;
                })
                .catch(() => {
                    // Revert changes when request fails
                    this.likedByUser = prevLikedByUser;
                    this.total = prevtotal;
                    this.btnDisabled = false;
                });
        },
    },
    computed: {
        likeApiRoute() {
            return `/api/v1/likes${!this.likeId ? "" : `/${this.likeId}`}`;
        },
    },
};
</script>
<style scoped>
.btn-like {
    align-items: center;
    background: #4aa5ff;
    border-radius: 100px;
    border: 0;
    color: #fff;
    display: flex;
    font-size: 14px;
    height: 75px;
    justify-content: center;
    transition: box-shadow 200ms;
    width: 75px;
}

.btn-like img {
    filter: invert(99%) sepia(0%) saturate(2%) hue-rotate(344deg) brightness(104%) contrast(101%);
}

.btn-like.active {
    background: #242939;
}

.btn-like.active img {
    width: 18px;
}

.btn-like:hover {
    box-shadow: 0 5px 30px rgb(0 0 0 / 50%), 0 4px 10px rgb(0 0 0 / 10%),
        0 1px 4px rgb(0 0 0 / 15%);
}

.icon-like {
    filter: invert(59%) sepia(2%) saturate(0%) hue-rotate(352deg) brightness(87%) contrast(89%);
}

.total {
    color: #ffffff80;
}
</style>
