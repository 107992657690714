import { itemViewerIsVisible } from "./browse-navigator";

declare const flagViewerNext: any;

function layoutIsTable(): boolean {
    const container = document.querySelector('.browse-container');

    if (container === null) return false;

    return container.classList.contains('layout-table');
}

function layoutIsGrid(): boolean {
    return !layoutIsTable();
}

function flagViewerNextIsDisabled(): boolean {
    return flagViewerNext === 0;
}

function openItemViewerModal(id: string, dynamicViewId: string): void {
    window.Livewire.dispatch('openModal', {
        component: 'item-viewer-modal',
        arguments: { id, dynamicViewId }
    });
}

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.viewer-entry').forEach((element) => {

        /**
         * Open the item viewer modal on click
         */
        element.addEventListener('click', (event) => {

            if (flagViewerNextIsDisabled()) return;

            if (layoutIsTable()) {
                // Open the modal if the 'expand' icon was clicked (on table view)
                const clickedElement = event.target as HTMLElement;

                if (
                    !clickedElement.classList.contains('browse-icon') && // @TODO replace browse-icon
                    !clickedElement.classList.contains('browse-thumb')
                ) {
                    return;
                }
            }

            const id = element.getAttribute('data-id');

            if (id === null) return;

            openItemViewerModal(id, window.dynamicViewId);
            event.preventDefault();
        }
        );
    });

    /**
     * Open the item viewer modal on 'Enter' key press
     */
    document.addEventListener('keydown', (event) => {
        if (event.code !== "Enter" || itemViewerIsVisible() || layoutIsGrid() || flagViewerNextIsDisabled()) {
            return;
        }

        const selectedTableRow = document.querySelector('.selected');

        if (selectedTableRow === null) return;

        const id = selectedTableRow.getAttribute('data-id');

        if (id === null) return;

        openItemViewerModal(id, window.dynamicViewId);
        event.preventDefault();
    });
});
