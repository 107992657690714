import Chart from 'chart.js/auto';

function floorAxis(label: any) {
    // when the floored value is the same as the value we have a whole number
    if (Math.floor(label) === label) {
        return label;
    }
}

// Set chart options
const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
    }
}

document.addEventListener("DOMContentLoaded", () => {
    // Growth Chart
    const growthLineChart = <HTMLCanvasElement>document.getElementById('growth-chart')
    if (growthLineChart !== null) {
        const canvas = growthLineChart.getContext('2d');
        const growthChartData = window.growthChartData;
        if (growthChartData && canvas !== null) {
            new Chart(canvas, {
                type: 'line',
                data: {
                    labels: growthChartData.labels,
                    datasets: [{
                        spanGaps: true,
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderColor: 'rgb(76, 199, 121)',
                        data: growthChartData.totals,
                    }]
                },
                options: {
                    ...chartOptions,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                callback: floorAxis,
                            }
                        }
                    }

                }
            });
        }
    }

    renderCategoryChart();
});

function renderCategoryChart() {
    const categoryBarChart = <HTMLCanvasElement>document.getElementById('category-chart')

    if (categoryBarChart === null) {
        return
    }

    const canvas = categoryBarChart.getContext('2d');
    const categoryChartLabels = window.categoryChartLabels;
    const categoryChartData = window.categoryChartData;

    if (categoryChartLabels && categoryChartData && canvas !== null) {
        new Chart(canvas, {
            type: 'bar',
            data: {
                labels: categoryChartLabels,
                datasets: [{
                    data: categoryChartData,
                    backgroundColor: '#4CC779',
                }],
            },
            options: {
                ...chartOptions,
                indexAxis: 'y',
                scales: {
                    x: {
                        beginAtZero: true,
                        ticks: {
                            callback: floorAxis,
                        }
                    }
                }
            }
        });
    }
}
