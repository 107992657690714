<template>
    <div class="mb-2 text-dark border p-4">
        <div class="mb-2 d-flex align-content-center">
            <img
                :src="entry.attributes.avatar"
                width="30"
                height="30"
                class="rounded-circle me-2"
                alt=""
            />
            <div>
                <span class="fw-bold">{{
                    entry.attributes.author
                }}</span>
                <small class="text-muted"
                    >&bull;
                    {{ entry.attributes["created-at"] | diffForHumans }}</small
                >
            </div>
        </div>
        <div class="comment-body">{{ entry.attributes.body }}</div>
    </div>
</template>
<script>
import dayjs from "dayjs";
import "dayjs/locale/nl";
import relativeTime from "dayjs/plugin/relativeTime";

export default {
    name: "Comment",
    props: {
        entry: {
            default: {},
            type: Object,
        },
    },
    created() {
        dayjs.extend(relativeTime);
        dayjs.locale("nl");
    },
    filters: {
        diffForHumans: (date) => {
            if (!date) {
                return null;
            }

            return dayjs(date).fromNow();
        },
    },
};
</script>
<style scoped>
.comment-body {
    white-space: break-spaces;
}
</style>
