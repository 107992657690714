<template>
    <div class="bg-white">
        <div class="col-md-8 mx-auto">
            <comment-form class="pb-4 pt-5" :id="id"></comment-form>
            <comment-list class="pb-5" :id="id"></comment-list>
        </div>
    </div>
</template>
<script>
import CommentForm from "../components/CommentForm.vue";
import CommentList from "../components/CommentList.vue";
export default {
    name: "Comments",
    props: {
        id: {
            default: null,
            type: String,
        },
    },
    components: {
        CommentForm,
        CommentList,
    }
};
</script>
