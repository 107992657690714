// navigate to next and previous item

import { Direction } from '../helpers/browse-view-navigator';

let itemViewerIsLoading = false;

document.addEventListener('livewire:init', () => {
    window.Livewire.on('item-viewer-navigate', handleNavigateEvent);
    window.Livewire.on('item-viewer-loaded', handleLoadedEvent);

    document.addEventListener('keydown', handleKeyEvent);
});

function handleKeyEvent(event: KeyboardEvent) {
    if (itemViewerIsNotVisible()) return;

    if (event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') return;

    window.Livewire.dispatch('item-viewer-navigate', {
        direction: event.key === 'ArrowRight' ? Direction.Next : Direction.Previous,
    });
}

export function itemViewerIsNotVisible() {
    return document.querySelector('[data-controller="item-viewer-title"]') === null;
}

export function itemViewerIsVisible() {
    return !itemViewerIsNotVisible();
}

function handleLoadedEvent(event: { id: string }) {
    itemViewerIsLoading = false;
    const item = document.querySelector(`[id="${event.id}"]`);
    if (item === null) return;

    // Select the item
    document.querySelector('.selected')?.classList.remove('selected');
    item.classList.add('selected');

    // Check if there is a next or previous item
    const hasPrev = findItemSibling(item, Direction.Previous) !== null;
    const hasNext = findItemSibling(item, Direction.Next) !== null;

    // Enable or disable navigation buttons
    const btnNext = document.querySelector('#item-viewer-btn-next');
    const btnPrev = document.querySelector('#item-viewer-btn-prev');

    if (btnNext === null || btnPrev === null) return;

    toggleItemNavigationButton(btnPrev, hasPrev);
    toggleItemNavigationButton(btnNext, hasNext);
}

function handleNavigateEvent(event: { direction: Direction }) {
    if (itemViewerIsLoading) return;

    itemViewerIsLoading = true;

    const selected = document.querySelector('.viewer-entry.selected');

    if (selected === null) return;

    const itemToLoad = findItemSibling(selected, event.direction);

    if (itemToLoad === null) {
        itemViewerIsLoading = false;
        return;
    }

    window.Livewire.dispatch('item-viewer-load', {
        id: itemToLoad.getAttribute('id'),
    });
}

function findItemSibling(element: Element, direction: Direction): Element | null {
    if (direction === Direction.Next) return element.nextElementSibling;
    if (direction === Direction.Previous) return element.previousElementSibling;

    return null;
}

function toggleItemNavigationButton(button: Element, exists: boolean) {
    if (exists) {
        button.removeAttribute('disabled');
        return;
    }

    button.setAttribute('disabled', 'disabled');
}
