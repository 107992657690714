<template>
    <div v-if="itemData" class="border-bottom">
        <div class="d-md-flex p-3 align-items-center">
            <div class="row-figure border me-4">
                <div class="row-image">
                    <img loading="lazy" v-if="hasImages" :src="thumbnail" alt="" />
                    <img v-if="!hasImages" width="120" src="../../images/image-placeholder.svg" alt="" />
                </div>
            </div>
            <div class="overflow-hidden">
                <div class="fw-bold text-truncate">{{ itemData.name }}</div>
                <div class="text-muted small mb-2">{{ meta }}</div>
                <div class="alert alert-warning small p-1 m-0 text-truncate" v-if="hints">
                    <a :href="itemData.edit_url" class="text-lowercase">{{
                        lang.edit
                    }}</a>,
                    {{ hints }}
                </div>
            </div>
            <div class="ms-auto">
                <button class="btn btn-sm ms-md-3 mt-3 mt-md-0 btn-outline-danger px-2" v-if="itemData.is_public" @click="confirmRemove()">
                    <svg class="me-2" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fill="#dc3545"
                            d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm4-9H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z" />
                    </svg>
                    {{ lang.remove }}
                </button>
                <button :disabled="!itemData.can_be_published" class="d-flex align-items-center ms-md-3 mt-3 mt-md-0 btn btn-sm btn-outline-success px-2"
                    v-if="!itemData.is_public" @click="selectImageOrAdd">
                    <svg class="me-2" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fill="#3F8256"
                            d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm4-9H13V8a1,1,0,0,0-2,0v3H8a1,1,0,0,0,0,2h3v3a1,1,0,0,0,2,0V13h3a1,1,0,0,0,0-2Z" />
                    </svg>
                    {{ lang.add }}<span v-if="itemData.images.length > 1">...</span>
                </button>
            </div>
        </div>
        <div class="row-footer p-3" v-if="isOpen">
            <div class="fw-bold mb-2">Selecteer een afbeelding:</div>

            <div class="mt-2 mb-3">
                <button @click="selectImage(image.id)" v-for="image in itemData.images" :class="`row-image-option rounded-0 btn me-2 p-0 ${image.id === selectedImage ? 'selected border-secondary' : 'border'}`">
                    <img  loading="lazy" :src="image.url" alt="" />
                </button>
            </div>

            <div class="d-flex">
                <button :disabled="selectedImage === null || isPatching" class="d-flex align-items-center btn btn-secondary" @click="add()">
                    {{ lang.add }}
                </button>

                <button class="ms-2 btn btn-outline-secondary" @click="toggle()">
                    {{ lang.cancel }}
                </button>
            </div>

        </div>
    </div>
</template>
<script>
/* eslint-disable no-undef */
export default {
    name: "ShowroomRow",
    data: () => ({
        lang: {
            remove: window.uiLanguage.remove,
            remove_confirm: window.uiLanguage.remove_from_showroom,
            add: window.uiLanguage.add,
            edit: window.uiLanguage.edit,
            cancel: window.uiLanguage.cancel,
            notification_added_to_showroom:
                window.uiLanguage.notification_added_to_showroom,
        },
        isOpen: false,
        selectedImage: null,
        isPatching: false,
        itemData: undefined,
    }),
    props: {
        item: {
            type: Object,
            default: () => { },
        },
    },
    mounted() {
        this.itemData = this.item;
    },
    methods: {
        setIsShowroomImage(selectedImageId) {
            const imagesReloaded = this.itemData.images.map((image) => {
                return { ...image, is_showroom_image: image.id === selectedImageId }
            })

            this.itemData = { ...this.itemData, images: imagesReloaded };
        },
        selectImage(id) {
            this.selectedImage = id;
        },
        selectImageOrAdd() {

            // When there is only one image, select it and add it
            if (this.itemData.images.length === 1) {
                this.selectedImage = this.itemData.images[0].id;
                this.add();
                return;
            }

            // Otherwise, open the footer with image selection
            this.toggle();
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },
        closeFooter() {
            this.isOpen = false;
        },
        add() {
            this.setIsShowroomImage(this.selectedImage)
            this.setIsPublic(true);

            // Show snackbar
            if (typeof Snackbar !== "undefined") {
                Snackbar.show({
                    text: this.lang.notification_added_to_showroom,
                    showAction: false,
                });
            }
        },
        remove() {
            this.setIsShowroomImage(null)
            this.setIsPublic(false);
        },
        /**
         * Set whether object is visible in showroom
         *
         * @param boolean isPublic
         */
        setIsPublic(isPublic) {
            // Send patch request to API
            this.update(this.itemData, isPublic);
            // Hide the dialog
            this.$modal.hide("dialog");
            // Update is_public attribute
            this.$set(this.itemData, "is_public", isPublic);
            this.$set(this.itemData, "can_be_published", !isPublic);
            // Update parent item
            this.$parent.item = this.itemData;
            // Emit change
            this.$emit("showroom-row-change");
        },
        update(item, isPublic) {
            // Send patch request to API
            this.isPatching = true;
            axios.patch(
                `/api/v1/items/${this.itemData.id}`,
                {
                    data: {
                        type: "items",
                        id: this.itemData.id,
                        attributes: {
                            is_public: isPublic,
                            showroom_media_id: this.selectedImage,
                        },
                    },
                },
                {
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                }
            ).then(() => {
                this.isPatching = false;
                this.closeFooter();
            });
        },
        confirmRemove() {
            const self = this;
            this.$modal.show("dialog", {
                text: `<strong>${this.lang.remove_confirm}</strong>`,
                buttons: [
                    {
                        title: this.lang.remove,
                        default: true,
                        handler: () => {
                            self.remove();
                        },
                        class: "vue-dialog-button primary",
                    },
                    {
                        title: this.lang.cancel,
                        handler: () => {
                            this.$modal.hide("dialog");
                        },
                    },
                ],
            });
        },
    },
    computed: {
        hasImages() {
            return this.itemData?.images.length > 0 ?? false;
        },
        thumbnail() {
            if (this.itemData.images.length === 0) {
                return;
            }

            if (this.showroomImage) {
                return this.showroomImage.url;
            }

            if (this.selectedImage === null) {
                return this.itemData.images[0].url;
            }

            return this.itemData.images.find((image) => image.id === this.selectedImage).url;
        },
        showroomImage() {
            return this.itemData.images.find((image) => image.is_showroom_image);
        },
        meta() {
            let list = [];
            const item = this.itemData;
            if (item.manufacturer_value !== null) list.push(item.manufacturer_value);
            if (item.item_no !== null) list.push(item.item_no);
            if (item.scale_value !== null) list.push(item.scale_value);
            return list.join(", ");
        },
        hints() {
            if (!Array.isArray(this.itemData.publishing_flags)) {
                return null;
            }
            return this.itemData.publishing_flags
                .map((item) => window.uiLanguage[item])
                .join(", ");
        },
    },
};
</script>
<style lang="scss" scoped>
.row-figure {
    flex: 0 0 120px;
    position: relative;
}

.row-figure::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(calc(100% - 8px) / (16 / 9));
}

.row-image {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-image-option {
    width: 10rem;
    aspect-ratio: 16 / 9;
    background: #fff;

    &:hover img {
        opacity: 1;
    }

    img {
        transition: opacity 150ms ease-in-out;
        object-fit: cover;
        opacity: 0.5;
        max-height: 100%;
        max-width: 100%;
    }

    &.selected img {
        opacity: 1;
    }

    &:focus {
        box-shadow: none;
    }
}


.row-image img {
    max-height: 100%;
    max-width: 100%;
}

.btn:not([disabled]):hover path {
    fill: #fff;
}

.row-footer {
    background: rgba(0,0,0,0.033);
    box-shadow: inset 0 10px 10px -10px rgb(0, 0, 0, 0.1)
}
</style>
