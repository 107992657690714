// Load choices for select element improvement
import Choices from "choices.js";

document.addEventListener("DOMContentLoaded", () => {
    const elements = Array.from(document.querySelectorAll(".js-choice"));
    elements.map((element) => {
        return new Choices(element, {
            removeItemButton: true,
            placeholder: true,
            placeholderValue: null,
            itemSelectText: "",
            noResultsText: window.uiLanguage.no_results,
            allowHTML: true,
            shouldSort: false,
        });
    });
});
