import $ from 'jquery';
import 'datatables.net';
import { ApiPageInfo } from 'datatables.net';

export enum Direction {
    Next = 'next',
    Previous = 'previous',
}

export async function getNeighbourEntryId(direction: Direction): Promise<string | null> {
    if (getSelectedEntry() === null) {
        return Promise.resolve(null);
    }

    const neighbourEntry = getNeighbourEntry(direction);

    if (neighbourEntry === null) {
        if (!getNeighbourPageExists(direction)) {
            return Promise.resolve(null);
        }

        return await drawTablePage(direction);
    }

    if (typeof neighbourEntry === 'undefined') {
        return Promise.resolve(null);
    }

    return Promise.resolve(neighbourEntry.id);
}

export function hasNeighbourEntry(direction: Direction): boolean {
    const neighbourRow = getNeighbourEntry(direction);

    if (neighbourRow === null) {
        return getNeighbourPageExists(direction);
    }

    return true;
}

export function getTableInfo(): ApiPageInfo | null {
    const table = getTable();

    if (table === null) {
        return null;
    }

    return table.page.info();
}

function getTable() {
    if (document.querySelector('.browse-table') === null) {
        return null;
    }

    return $('.browse-table').DataTable();
}

function getSelectedEntry(): null | HTMLTableRowElement | HTMLDivElement {
    return document.querySelector('.browse-entry.selected');
}

function getNeighbourEntry(direction: Direction): undefined | HTMLTableRowElement | HTMLDivElement {
    const selectedEntry = getSelectedEntry();

    if (selectedEntry === null) {
        return undefined;
    }

    // Table layout
    if (selectedEntry instanceof HTMLTableRowElement) {
        const table = getTable();

        if (table === null) {
            return undefined;
        }

        const selectedRowIndex = table.row(selectedEntry).index();
        const neighbourRowIndex = direction === Direction.Next ? selectedRowIndex + 1 : selectedRowIndex - 1;

        return (table.row(neighbourRowIndex).node() as HTMLTableRowElement);
    }

    // Grid layout
    if (selectedEntry instanceof HTMLDivElement) {
        const allEntries = Array.from(document.querySelectorAll('.browse-entry'));
        const currentIndex = allEntries.indexOf(selectedEntry);

        const foundEntry = allEntries[currentIndex + (direction === Direction.Next ? 1 : -1)];

        return (foundEntry as HTMLDivElement);
    }

    return undefined;
}

function getNeighbourPageExists(direction: Direction): boolean {
    const table = getTable();

    if (table === null) {
        return false;
    }

    const currentPage = table.page();
    const lastPage = table.page.info().pages;

    if (direction === Direction.Next) {
        return currentPage + 1 < lastPage;
    }

    return currentPage > 0;
}

async function drawTablePage(direction: Direction): Promise<string | null> {
    const table = getTable();

    if (table === null) {
        return null;
    }

    table.page(direction).draw('page');

    return new Promise((resolve) => {
        table.on('draw', function () {
            const selector = direction === Direction.Next ? 'first-child' : 'last-child';
            const tableRowOnPageToSelect = document.querySelector(`tr.browse-entry:${selector}`);

            if (tableRowOnPageToSelect === null) {
                return resolve(null);
            }

            resolve(tableRowOnPageToSelect.id);
        });
    });
}
