const statsNumberCards = () =>
    document.querySelectorAll(
        '[data-type="number"]',
    ) as NodeListOf<HTMLElement>;

const statsMoneyCards = () =>
    document.querySelectorAll('[data-type="money"]') as NodeListOf<HTMLElement>;

const locale = "nl-NL";

// How long you want the animation to take, in ms
const animationDuration = 1500;

// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;

// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round(animationDuration / frameDuration);

// An ease-out function that slows the count as it progresses
const easeOutQuad = (t: number) => t * (2 - t);

const statsCountAnimation = () => {
    statsNumberCards().forEach(animateCountUp);
    statsMoneyCards().forEach(animateCountUp);
};

const animateCountUp = (el: HTMLElement) => {
    let frame = 0;
    const value = el.getAttribute("data-value");

    if (value === null) {
        return;
    }

    const countTo = parseInt(value);
    // Start the animation running 60 times per second
    const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames);
        const currentCount = Math.round(countTo * progress);

        // If the current count has changed, update the element
        if (parseInt(value) >= currentCount) {
            parseCounterValue(el, currentCount);
        }

        // If we’ve reached our last frame, stop the animation
        if (frame === totalFrames) {
            clearInterval(counter);
        }
    }, frameDuration);
};

const parseCounterValue = (el: HTMLElement, currentCount: number) => {
    if (el.getAttribute("data-type") === null) {
        return;
    }

    el.innerText =
        el.getAttribute("data-type") === "money"
            ? parseMoneyValue(currentCount / 100)
            : parseNumberValue(currentCount / 100);
};

const parseNumberValue = (val: number) =>
    val.toLocaleString(locale, { maximumFractionDigits: 0 });

const parseMoneyValue = (val: number) =>
    `€  ${val.toLocaleString(locale, { minimumFractionDigits: 2 })}`;

document.addEventListener("DOMContentLoaded", statsCountAnimation);
