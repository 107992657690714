import { Sortable } from '@shopify/draggable';

document.addEventListener("DOMContentLoaded", () => {
    const containerSelector = '#dynamic-views-columns.sortable-list';
    new Sortable(document.querySelectorAll(containerSelector), {
        draggable: '.draggable',
        handle: '.handle',
        mirror: {
            appendTo: containerSelector,
            constrainDimensions: true,
        },
    });
});
