<template>
    <modal :adaptive="true" height="auto" :scrollable="true" width="100%" :maxWidth="1400" name="explore-modal"
        @closed="closed" class="transparent" :class="activeClass">
        <button v-if="modalActive" @click="closeModal" class="explore-modal-close">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="#fff"
                    d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />
            </svg>
        </button>
        <item theme="transparent" v-if="item && image" :like-count="item.relationships.likes.meta.count"
            :like="item.relationships.likes.meta.liked" :id="item.id" :attributes="item.attributes" :image="image">
        </item>
    </modal>
</template>
<style scoped>
.explore-modal-close {
    background: transparent;
    border: 0;
    margin-left: auto;
    padding: 10px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: 200ms opacity;
}

.explore-modal-close:hover {
    opacity: 0.5;
}
</style>
<script>
import Item from "../components/Item.vue";
export default {
    name: "ExploreModal",
    data() {
        return {
            item: null,
            startUrl: null,
            image: null,
        };
    },
    props: {
        modalActive: {
            default: false,
            type: Boolean,
        },
    },
    components: {
        Item,
    },
    created() {
        const vm = this;

        this.$eventBus.$on("explore-modal-open", function (id) {
            vm.showModal(id);
        });
    },
    computed: {
        activeClass: function () {
            return {
                "modal-active": this.modalActive ? true : false,
            };
        },
    },
    methods: {
        showModal(id) {
            this.$modal.show("explore-modal");
            this.fetchSingleItem(id);
            // Store current URL
            this.startUrl = window.location.pathname;
            // Update the browser's URL
            history.pushState({ id: id }, "Yard Tower", `/explore/view/${id}`);
        },
        async fetchSingleItem(id) {
            const fields = [
                'avatar',
                'author',
                'comments',
                'era',
                'image',
                'likes',
                'manufacturer',
                'name',
                'scale',
            ];
            return await axios.get(`/api/v1/items/${id}?fields[items]=${fields.join(',')}`).then((response) => {
                this.item = response.data.data;
                this.getImage(response.data.data);
            });
        },
        getImage(item) {
            const showroomImage = item.attributes.image.find((image) => image.tags.includes('showroom'));
            this.image = showroomImage.sizes.large;
        },
        closeModal() {
            this.$modal.hide("explore-modal");
        },
        closed() {
            // Reset the browser's URL
            history.pushState({ id: null }, "Yard Tower", this.startUrl);
            // Reset variables
            this.item = null;
            this.image = null;
            this.startUrl = null;
        },
    },
};
</script>
