import $ from "jquery";
window.$ = $;
import JSZip from "jszip";
import DataTable from "datatables.net-dt";
import "datatables.net-buttons-dt";
import "datatables.net-buttons/js/buttons.html5";

window.JSZip = JSZip;
let DataTablePre = null;

const titleIsEmtpy = () => window.dynamicViewTitle === null || window.dynamicViewTitle === "";
const fileName = () => `Yard Tower${titleIsEmtpy() ? "" : ` - ${window.dynamicViewTitle}`} - ${DataTablePre}`;

function initDataTable() {

    if (document.querySelector('body.print')) { // Don't initialize DataTables on print page
        return;
    }

    if (document.querySelectorAll('.browse-table').length === 0) {
        return;
    }

    const tableExportOptions = { columns: "thead th:not(.no-export)" };

    // Disable sorting on first three columns
    const orderSetting = document.querySelectorAll('.browse-table th').length > 3 ? [[3, "asc"]] : [];

    const layout = {
        topStart: 'search',
        topEnd: {
            features: ['buttons', 'pageLength'],
        },
        bottomStart: 'info',
        bottomEnd: 'paging',
    }

    // Disable sorting image column if it exists
    const imageColumnIndex = Array.from(document.querySelectorAll('.browse-table th')).findIndex(th => th.dataset.key === 'image');
    const hasImageColumn = imageColumnIndex !== -1;
    const imageColumnDef = hasImageColumn ? { targets: imageColumnIndex, orderable: false } : {};

    const settings = {
        ajax: {
            url: window.dynamicViewPageUrl,
            data: function (d) {
                // Get the 'new' parameter from the URL
                const urlParams = new URLSearchParams(window.location.search);
                const newParam = urlParams.get('new');

                if (newParam) {
                    d.new = newParam;
                }
            }
        },
        pageLength: 100,
        lengthMenu: [10, 50, 100, 200, 500],
        processing: true,
        serverSide: true,
        order: orderSetting,
        stateSave: true,
        scrollCollapse: true,
        scrollResize: true,
        scrollX: true,
        buttons: [
            {
                extend: "collection",
                fade: false,
                text: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M6 13h4v-7h4v7h4l-6 6-6-6zm16-1c0 5.514-4.486 10-10 10s-10-4.486-10-10 4.486-10 10-10 10 4.486 10 10zm2 0c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12z"/></svg><span class="d-none d-md-inline-block">Download</span>',
                buttons: [
                    {
                        text: "PDF-document (.pdf)",
                        action: () => {
                            window.open(window.printUrl, '_blank');
                        }
                    },
                    {
                        extend: "excelHtml5",
                        exportOptions: tableExportOptions,
                        filename: fileName,
                        text: "Microsoft Excel (.xlsx)",
                    },
                    {
                        extend: "csvHtml5",
                        exportOptions: tableExportOptions,
                        filename: fileName,
                        text: "CSV-document (.csv)",
                    },
                ],
            },
        ],
        layout: layout,
        language: dataTableLanguage,
        columnDefs: [
            imageColumnDef,
            { width: 30, targets: 0, orderable: false },
            { width: 30, targets: 1, orderable: false },
        ],
        fnRowCallback: (nRow, test, nIndex) => {
            // Add index for prev / next navigation with arrow keys
            nRow.setAttribute('data-index', `${nIndex + 1}`);
            nRow.classList.add('browse-entry');

            // Lock a row
            if (nRow.children[0].children[0].getAttribute('data-status') !== 'locked') {
                return nRow;
            }
            nRow.classList.add('text-blurred');

            return nRow;
        },
        infoCallback: (settings, start, end, max, total, pre) => {
            if (pre !== null) {
                DataTablePre = pre;
            }

            return pre;
        },
    };
    const table = new DataTable(".browse-table", settings);

    table.on("draw", () => {
        jQuery('[data-bs-toggle="tooltip"]').tooltip();
    });

    $(document)
        .off("click", ".browse-table tbody tr")
        .on("click", ".browse-table tbody tr", function () {
            const self = $(this);
            if (!self.hasClass("selected")) {
                table.$("tr.selected").removeClass("selected");
                self.addClass("selected");
            }
        });
}

function stopPropagationOnDropDownMenuClick() {
    $(".dropdown-menu").on("click", function (e) {
        e.stopPropagation();
    });
}

$(function () {
    initDataTable();
    stopPropagationOnDropDownMenuClick();
});
