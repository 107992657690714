const confirmDelete = (element: HTMLElement) => {
    const attributes = element.getAttribute("data-confirm-attr");
    const component = element.getAttribute("data-confirm");

    if (!attributes || !component) {
        return;
    }

    window.Livewire.dispatch("openModal", {
        component,
        arguments: JSON.parse(attributes),
    });
}

document.addEventListener("DOMContentLoaded", (): void => {
    const destroyModel = (event: Event) => {
        event.preventDefault();
        confirmDelete(event.target as HTMLElement);
    };

    // Add click events
    const confirmButtons = document.querySelectorAll("[data-confirm]");
    Array.from(confirmButtons).map((button): void => {
        button.addEventListener("click", destroyModel);
    });
});
