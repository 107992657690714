const previewImage = (event: Event, parentEl: HTMLElement) => {

    const target = event.target as HTMLInputElement;
    const selectedImage = parentEl.querySelector(".image-selected") as HTMLImageElement;
    const imageDelete = parentEl.querySelector(".image-delete") as HTMLInputElement;

    /**
     * If at least one image is selected, then proceed to display the preview.
     */
    if (selectedImage && target.files && target.files.length) {
        const imageSrc = URL.createObjectURL(target.files[0]);

        /**
         * Set the preview image source.
         */
        selectedImage.src = imageSrc;

        /**
         * Show the preview image.
         */
        parentEl.classList.add("has-image");

        if (imageDelete) {
            imageDelete.checked = false;
        }
    }
};

const resetImagePreview = (parentEl: HTMLElement) => {
    const imageSelected = parentEl.querySelector(".image-selected") as HTMLInputElement;
    const imageDelete = parentEl.querySelector(".image-delete") as HTMLInputElement;
    const imageFile = parentEl.querySelector(".image-file") as HTMLInputElement;

    if (imageSelected) {
        imageSelected.value = "";
    }

    if (imageDelete) {
        imageDelete.checked = true;
    }

    if (imageFile) {
        imageFile.value = "";
    }

    parentEl.classList.remove("has-image");
}

window.addEventListener("load", () => {
    const imageUploadElements: NodeListOf<HTMLElement> = document.querySelectorAll(".image-upload");

    Array.from(imageUploadElements).map((element: HTMLElement) => {
        const resetButton = element.querySelector(".image-reset-btn");
        const imageFile = element.querySelector(".image-file");

        if (resetButton) {
            resetButton.addEventListener("click", () => resetImagePreview(element));
        }

        if (imageFile) {
            imageFile.addEventListener("change", (event: Event) => previewImage(event, element));
        }
    });
});
