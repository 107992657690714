import $ from 'jquery';
window.$ = window.jQuery = $;

jQuery(() => {
    $(".btn-hide-all").on('click', function (e: JQuery.TriggeredEvent) {
        e.preventDefault();
        const element = e.target as HTMLInputElement;
        $(`[data-bs-toggle="${$(element).attr("data-group")}"]`).prop(
            "checked",
            false
        );
        $(".terms-toggle").prop("checked", false);
    });

    $(".btn-show-all").on("click", function (e: JQuery.TriggeredEvent) {
        e.preventDefault();
        const element = e.target as HTMLInputElement;
        $(`[data-bs-toggle="${$(element).attr("data-group")}"]`).prop(
            "checked",
            true
        );
    });
});
