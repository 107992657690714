<template>
    <div class="viewer-side">
        <div class="viewer-data" :class="{ active: showDetails }">
            <div v-if="isLocked">
                <div class="alert-warning rounded p-4">
                    <h4>{{ language.locked }}</h4>
                    <p class="m-0">
                        <strong>{{ language.this_object_is_locked }}</strong>&nbsp;
                        <span v-if="isOwner">{{ language.upgrade_your_account }}</span>
                        <span v-if="!isOwner">{{ language.owner_should_upgrade_account }}</span>
                    </p>
                </div>
                <a v-if="isOwner" class="mt-3 d-block btn btn-secondary" href="/upgrade">
                    {{ language.upgrade }}
                </a>
            </div>
            <div v-if="showInfo">
                <h3 class="viewer-title">
                    {{ info.name.value }}
                </h3>
                <div class="viewer-subtitle">
                    {{ subtitle() }}
                </div>
                <div v-if="info.category" class="viewer-row">
                    <div class="viewer-label">{{ info.category.label }}</div>
                    {{ info.category.value === null ? "-" : info.category.value }}
                </div>
                <div v-if="info.era" class="viewer-row">
                    <div class="viewer-label">{{ info.era.label }}</div>
                    {{ info.era.value === null ? "-" : info.era.value }}
                </div>
                <div v-if="info.scale" class="viewer-row">
                    <div class="viewer-label">{{ info.scale.label }}</div>
                    {{ info.scale.value === null ? "-" : info.scale.value }}
                </div>
                <div v-if="info.country" class="viewer-row">
                    <div class="viewer-label">{{ info.country.label }}</div>
                    {{ info.country.value === null ? "-" : info.country.value }}
                </div>
                <button v-if="hasExtraInfo" @click="toggleInformation()" class="viewer-more"
                    :class="{ active: showInformation }">
                    {{ language.all_information }}

                    <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fill="#4771D0"
                            d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z" />
                    </svg>
                </button>
                <div v-if="showInformation">
                    <div :key="key" v-for="(attribute, key) in filteredAttributes"
                        class="info-row d-flex text-truncate border-bottom">
                        <div class="text-muted info-label">
                            {{ attribute.label }}
                        </div>
                        <div :class="`text-truncate ${lineClass(key)}`">{{ attribute.value }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="viewer-bar">
            <button :class="{ active: !showDetails }" @click="toggleDetails(false)"
                class="viewer-bar-btn-md-none viewer-bar-btn">
                <svg class="viewer-bar-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="#313E4D"
                        d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a2.81,2.81,0,0,0,.49-.05l.3-.07.07,0h0l.05,0,.37-.14.13-.07c.1-.06.21-.11.31-.18a3.79,3.79,0,0,0,.38-.32l.07-.09a2.69,2.69,0,0,0,.27-.32l.09-.13a2.31,2.31,0,0,0,.18-.35,1,1,0,0,0,.07-.15c.05-.12.08-.25.12-.38l0-.15A2.6,2.6,0,0,0,22,19V5A3,3,0,0,0,19,2ZM5,20a1,1,0,0,1-1-1V14.69l3.29-3.3h0a1,1,0,0,1,1.42,0L17.31,20Zm15-1a1,1,0,0,1-.07.36,1,1,0,0,1-.08.14.94.94,0,0,1-.09.12l-5.35-5.35.88-.88a1,1,0,0,1,1.42,0h0L20,16.69Zm0-5.14L18.12,12a3.08,3.08,0,0,0-4.24,0l-.88.88L10.12,10a3.08,3.08,0,0,0-4.24,0L4,11.86V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z" />
                </svg>
                <span class="viewer-bar-btn-label">
                    {{ language.gallery }}
                </span>
            </button>
            <button :class="{ active: showDetails }" @click="toggleDetails(true)"
                class="viewer-bar-btn-md-none viewer-bar-btn">
                <svg class="viewer-bar-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="#313E4D"
                        d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z" />
                </svg>
                <span class="viewer-bar-btn-label">
                    {{ language.features }}
                </span>
            </button>

            <button :disabled="isLocked || !canUpdate" @click="editItem(id)" class="viewer-bar-btn"
                :title="language.edit">
                <svg class="viewer-bar-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="#313E4D"
                        d="M22,7.24a1,1,0,0,0-.29-.71L17.47,2.29A1,1,0,0,0,16.76,2a1,1,0,0,0-.71.29L13.22,5.12h0L2.29,16.05a1,1,0,0,0-.29.71V21a1,1,0,0,0,1,1H7.24A1,1,0,0,0,8,21.71L18.87,10.78h0L21.71,8a1.19,1.19,0,0,0,.22-.33,1,1,0,0,0,0-.24.7.7,0,0,0,0-.14ZM6.83,20H4V17.17l9.93-9.93,2.83,2.83ZM18.17,8.66,15.34,5.83l1.42-1.41,2.82,2.82Z" />
                </svg>
                <span class="viewer-bar-btn-label">
                    {{ language.edit }}
                </span>
            </button>

            <div class="d-none d-md-block viewer-bar-spacer"></div>

            <button :disabled="!canDelete" @click="confirmDestroy()" class="viewer-bar-btn" :title="language.delete">
                <svg class="viewer-bar-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="#313E4D"
                        d="M20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Z" />
                </svg>
                <span class="viewer-bar-btn-label">
                    {{ language.delete }}
                </span>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "ViewerSidebar",
    props: {
        id: {
            type: String,
            default: null,
        },
        language: {
            type: Object,
            default: () => { },
        },
        info: {
            type: Object,
            default: () => ({})
        },
        detailsIsVisible: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
            showInformation: false,
            showDetails: false,
        };
    },
    mounted() {
        this.showInformation = this.getStoredInformationPaneState();
        this.toggleDetails(this.detailsIsVisible);
    },
    computed: {
        showInfo() {
            return Object.keys(this.info).length > 0 && !this.isLocked;
        },
        hasExtraInfo() {
            return Object.keys(this.filteredAttributes).length > 0
        },
        isLocked() {
            return this.info?.code === 402;
        },
        filteredAttributes: function () {
            const itemData = Object.entries(this.info);

            // Remove fields that should be hidden
            const sanitizedData = itemData.filter(([key]) => key !== "name" && key !== "author" && key !== "avatar" && key !== "image" && key !== "is_owner" && key !== "capabilities");

            // Convert the key/value array back to an object
            return Object.fromEntries(sanitizedData);
        },
        isOwner() {
            return this.info?.is_owner ?? false;
        },
        canUpdate() {
            return this.info?.capabilities?.update ?? false;
        },
        canDelete() {
            return this.info?.capabilities?.delete ?? false;
        },
    },
    methods: {
        lineClass: (key: string | number) => {
            if (key === "remarks" || key === "description" || key === "short_description" || key === "product_description") {
                return "multiline";
            }

            return "";
        },
        getStoredInformationPaneState: () => {
            const storedVal = localStorage.object_modal_show_information;
            return storedVal === null ? false : storedVal === "true";
        },
        editItem: (id: string) => {
            const postfix =
                (typeof window.dynamicViewId === 'undefined') ? '' : `?return=${window.dynamicViewId}`;
            window.location.href = `${window.appItemsIndexUrl}/${id}/edit${postfix}`;
        },
        subtitle: function () {
            let parts = [],
                info = this.info;

            if (!(typeof info.quantity === 'undefined') && info.quantity.value > 1) {
                parts.push(`${info.quantity.value}x `);
            }

            if (!(typeof info.item_no === 'undefined') && info.item_no.value !== null) {
                parts.push(info.item_no.value);
            }

            if (!(typeof info.manufacturer === 'undefined') && info.manufacturer.value !== null) {
                parts.push(info.manufacturer.value);
            }

            return parts.join(" - ");
        },
        toggleInformation: function () {
            this.showInformation = !this.showInformation;

            localStorage.object_modal_show_information = this.showInformation;
        },
        toggleDetails: function (isVisible: boolean) {
            this.showDetails = isVisible;
        },
        confirmDestroy: function () {
            const self = this;
            this.$modal.show("dialog", {
                text: `<strong>${this.language.confirm_delete}</strong><br>${this.language.undo_warning}`,
                buttons: [
                    {
                        title: this.language.delete_btn,
                        default: true,
                        handler: () => {
                            self.destroyItem();
                        },
                        class: "vue-dialog-button primary",
                    },
                    {
                        title: this.language.cancel,
                        handler: () => {
                            this.$modal.hide("dialog");
                        },
                    },
                ],
            });
        },
        destroyItem() {
            window.axios.delete(`/api/v1/items/${this.id}`).then(() => {
                location.reload();
            });
        },
    },
};
</script>
<style scoped>
.multiline {
    white-space: pre-wrap;
    max-height: 25vh;
    overflow-y: auto;
}
</style>
