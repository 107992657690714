<template>
    <div v-if="images.length > 1" class="viewer-thumbnails">
        <button @click="toggleImage(image.large, index)" :class="`viewer-thumbnail ${isActive(index) ? 'active' : ''}`"
            v-for="(image, index) in list" :key="image.small">
            <img :src="image.small" />
        </button>
    </div>
</template>
<script lang="ts">
import { ImageTags } from "../../enums/image-tags";
import { SingleImage } from "../../types/single-image";

export default {
    name: "ThumbnailBar",
    props: {
        images: {
            type: Array<SingleImage>,
            required: true
        }
    },
    data: () => {
        return {
            selected: 0,
        }
    },
    created() {
        const vm = this;
        this.$eventBus.$on("item-viewer-load", () => {
            vm.selected = 0;
        });
    },
    computed: {
        list() {
            const images = this.images

            // Move featured image to front of the list
            const featured = images.find((img) => img.tags.includes(ImageTags.FEATURED));
            if (featured) {
                const index = images.indexOf(featured);
                images.splice(index, 1);
                images.unshift(featured);
            }

            return images.map((img) => img.sizes);
        }
    },
    methods: {
        toggleImage(image: string, index: number) {
            this.selected = index;
            this.$emit('toggle-image', image);
        },
        isActive(index: number) {
            return this.selected === index;
        }
    }
}
</script>
<style lang="scss">
.viewer-thumbnails {
    background: var(--yt-bg-oil-700);
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    list-style-type: none;
    display: flex;
    align-items: end;
    bottom: 7.375rem;
    justify-content: center;
    z-index: 10;
    height: 3rem;

    @media (min-width: 992px) {
        bottom: 3rem;
        right: 491px;
    }
}

.viewer-thumbnail {
    border: 0;
    background: #fff;
    padding: 0;
    opacity: 0.5;
    transition: opacity 150ms ease-in-out;
    aspect-ratio: 16/9;
    height: 2.5rem;

    img {
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
    }

    &.active,
    &:hover {
        opacity: 1;
    }
}
</style>
