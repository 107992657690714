import VModal from "vue-js-modal";
import Vue from "vue";

// Load Vue Modal
Vue.use(VModal, { dialog: true });

// Global event bus
declare module 'vue/types/vue' {
    interface Vue {
        $eventBus: Vue
    }
}

Vue.prototype.$eventBus = new Vue();

import ExploreModal from "./components/ExploreModal.vue";
import ShowroomList from "./components/ShowroomList.vue";
import ObjectModal from "./components/ObjectModal.vue";
import ShowroomRow from "./components/ShowroomRow.vue";
import Item from "./components/Item.vue";

// Load Vue components
Vue.component("explore-modal", ExploreModal);
Vue.component("object-modal", ObjectModal);
Vue.component("showroom-list", ShowroomList);
Vue.component("showroom-row", ShowroomRow);
Vue.component("item", Item);

window.vueApp = new Vue({
    name: "ItemViewer",
    el: "#app",
    methods: {
        openItemViewer: function () {
            this.$eventBus.$emit("item-viewer-open");
        },
        showExploreModal: function (id: string) {
            this.$eventBus.$emit("explore-modal-open", id);
        },
    },
});
